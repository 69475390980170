import React, { useRef, useState } from 'react';
import { executeRecaptcha, subscribeToNewsletterApi } from 'arts/config/api';
import classnames from 'classnames';
import Classes from '../my_referral/styles';
import SubscribeForm from '../subscribe_to_newsletter/subscribe_form';
import { onUserNewletterSubscribed } from '../../config/analytics';

const PromoSubscribe = () => {
  const ref = useRef(null);
  const [isSubscribed, setSubscribed] = useState(false);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const { email } = values;
    setSubmitting(true);
    executeRecaptcha('subscribe').then((token) => {
      subscribeToNewsletterApi(email, token).then(({ emailHash, email: newEmail, eventId }) => {
        if (!ref.current) { return; }
        setSubmitting(false);
        if (newEmail) {
          onUserNewletterSubscribed(emailHash, eventId);
          setSubscribed(true);
        } else {
          setErrors({ email: 'Invalid email address' });
        }
      }).catch(() => {
        if (!ref.current) { return; }
        setSubmitting(false);
      });
    });
  };

  const content = isSubscribed ? (
    <div className={ Classes.header }>
      <h1>
        Thank You!
      </h1>
      <div className={ Classes.div } />
      <p>
        You are now subscribed to get our latest news. The promo code is on its way to your inbox.
      </p>
    </div>
  ) : (
    <>
      <div className={ Classes.header }>
        <h1>
          Receive a Promo Code for 15% Off
        </h1>
        <p>
          your first purchase at Artfully Walls
        </p>
        <div className={ Classes.div } />
        <p>
          Get early access to future sales, promo codes,
          <br />
          new arrivals and more!
        </p>
      </div>
      <div>
        <SubscribeForm onSubmit={ onSubmit } />
      </div>
    </>
  );

  return (
    <div className={ Classes.referrals } ref={ ref }>
      <div className={ classnames(Classes.image, Classes.image9) } />
      <div className={ Classes.referral }>
        <div className={ Classes.r }>
          { content }
        </div>
      </div>
    </div>
  );
};

export default PromoSubscribe;
