import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'arts/components/InfiniteScroll';
import Loader from 'arts/components/Loader';
import Container from './container';
import Wall from './wall';
import GTMPageView from '../Application/data_layer';

import AppClasses from '../Application/styles';

// eslint-disable-next-line react/prop-types
const ParentContainer = (parentRef) => ({ children }) => (
  <Container ref={ parentRef }>
    { children }
  </Container>
);

const MyWalls = ({
  loadMyWalls,
  deleteMyWall,
  loading,
  walls,
  hasMore,
  page,
  deletingId
}) => {
  const canFetch = () => !loading && hasMore;

  const loadNextPage = () => {
    if (!canFetch()) { return; }
    loadMyWalls(page + 1);
  };

  const parentRef = useRef(null);

  const renderWall = (wall) => (
    <Wall
      { ...wall }
      key={ wall.id }
      deleteMyWall={ deleteMyWall }
      isDeleting={ wall.id === deletingId }
    />
  );

  const pc = useMemo(() => ParentContainer(parentRef), [parentRef]);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="other" section="my" />
      <InfiniteScroll
        items={ walls }
        itemRenderer={ renderWall }
        onLoad={ loadNextPage }
        threshold={ 728 }
        itemsContainer={ pc }
        forwardRef={ parentRef }
      />
      {
        loading
          ? <Loader text="Loading more items, hold on for a sec..." />
          : null
      }
    </div>
  );
};

MyWalls.propTypes = {
  loadMyWalls: PropTypes.func.isRequired,
  deleteMyWall: PropTypes.func.isRequired,
  // my_walls
  loading: PropTypes.bool.isRequired,
  walls: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  deletingId: PropTypes.number
};

MyWalls.defaultProps = {
  deletingId: null
};

export default MyWalls;
