import { connect } from 'react-redux';
import {
  fetchWorksPage, fetchArtFilters
} from 'arts/actions/works';
import Works from './works';

const mapStateToProps = (
  {
    works: {
      collection, error, loading, page, totalPages, loadedAt
    },
    artFilters: { filters }
  }
) => ({
  works: collection,
  error,
  loading,
  page,
  totalPages,
  artFilters: filters,
  loadedAt,
  gtmSection: 'art prints'
});

export default connect(mapStateToProps, {
  fetchWorksPage,
  fetchArtFilters
})(Works);
