import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

const ScrollAppearMonitor = ({ onAppear, className, children }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && onAppear) {
      onAppear();
    }
  }, [inView]);

  return (
    <div className={ className } ref={ ref }>
      { children(inView) }
    </div>
  );
};

ScrollAppearMonitor.propTypes = {
  onAppear: PropTypes.func,
  className: PropTypes.string.isRequired,
  children: PropTypes.any
};

export default ScrollAppearMonitor;
