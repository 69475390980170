import Constants from 'arts/config/constants';
import { loadWorksPageApi, fetchArtFiltersApi } from 'arts/config/api';
import { buildFetchWorksPage } from 'arts/config/works_loader_actions';

const artFiltersFetched = (filters) => ({
  type: Constants.ArtFilters.FETCH_SUCCESS,
  filters
});

export const fetchWorksPage = buildFetchWorksPage(Constants.Works, loadWorksPageApi, 'works');

export const fetchArtFilters = () => (dispatch) => {
  fetchArtFiltersApi().then(
    (data) => dispatch(artFiltersFetched(data))
  ).catch(() => {});
};
