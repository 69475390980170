import Constants from 'arts/config/constants';
import { loadTapestriesPageApi } from 'arts/config/api';
// import { loadTapestriesPageApi, fetchArtFiltersApi } from 'arts/config/api';
import { buildFetchWorksPage } from 'arts/config/works_loader_actions';

export const fetchWorksPage = buildFetchWorksPage(Constants.Tapestries, loadTapestriesPageApi, 'tapestries');

// const artFiltersFetched = (filters) => ({
//   type: Constants.ArtFilters.FETCH_SUCCESS,
//   filters
// });

// export const fetchArtFilters = () => (dispatch) => {
//   fetchArtFiltersApi().then(
//     (data) => dispatch(artFiltersFetched(data))
//   ).catch(() => {});
// };
