import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SELECTOR } from 'arts/config/constants';
import { executeRecaptcha, subscribeToNewsletterApi } from 'arts/config/api';
import { trackEvent, onUserNewletterSubscribed } from 'arts/config/analytics';
import Modal from '../Modal';
import AppClasses from '../Application/styles';
import ThanksPopup from './thanks_popup';
import SubscribeForm from './subscribe_form';
import Classes from './styles';

const SubscribePopup = ({ hidePopup, disallow, visitsCount }) => {
  const ref = useRef(null);
  const [isSubscribed, setSubscribed] = useState(false);

  useEffect(() => {
    trackEvent('subscribe popup', { visitsCount });
  }, []);

  const cancelPopup = () => {
    trackEvent('subscribe popup cancelled', { visitsCount });
    hidePopup();
  };

  if (isSubscribed) {
    return <ThanksPopup onCancel={ disallow } />;
  }

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const { email } = values;
    setSubmitting(true);
    executeRecaptcha('subscribe').then((token) => {
      subscribeToNewsletterApi(email, token).then(({ emailHash, email: newEmail, eventId }) => {
        if (!ref.current) { return; }
        setSubmitting(false);
        if (newEmail) {
          onUserNewletterSubscribed(emailHash, eventId);
          setSubscribed(true);
        } else {
          setErrors({ email: 'Invalid email address' });
        }
      }).catch(() => {
        if (!ref.current) { return; }
        setSubmitting(false);
      });
    });
  };

  return (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ cancelPopup }
      className={ AppClasses.thanksForAction }
      ref={ ref }
    >
      <h2>
        Enjoy 15% Off
      </h2>
      <p>
        your first purchase
      </p>
      <p>
        and get early access to sales &amp; new arrivals
      </p>
      <div className={ AppClasses.div } />
      <div className={ Classes.subscribeForm }>
        <SubscribeForm onSubmit={ onSubmit } />
      </div>
    </Modal>
  );
};

SubscribePopup.propTypes = {
  hidePopup: PropTypes.func.isRequired,
  disallow: PropTypes.func.isRequired,
  visitsCount: PropTypes.number
};

export default SubscribePopup;
