import { combineReducers } from 'redux';
import work from './work';
import works from './works';
import selection from './selection';
import expandable from './expandable';
import artists from './artists';
import artist from './artist';
import artistWorks from './artistWorks';
import relatedWorks from './relatedWorks';
import product from './product';
import productsList from './productsList';
import artFilters from './art_filters';
import wallFilters from './wall_filters';
import originalArtFilters from './original_art_filters';
import artistFilters from './artist_filters';
import giftCardBalance from './gift_card_balance';
import similarWorks from './similar_works';
import favorites from './favorites';
import collections from './collections';
import collectionDetails from './collection_details';
import designerPicks from './designer_picks';
import giftGuides from './gift_guides';
import walls from './walls';
import wall from './wall';
import authorOriginalArt from './author_original_art';
import originalArts from './original_arts';
import originalArt from './original_art';
import myWalls from './my_walls';
import cartItems from './cart_items';
import checkoutProcess from './checkout_process';
import mailSubscription from './mail_subscription';
import applePaySession from './apple_pay_session';
import exploreArt from './explore_art';
import tapestries from './tapestries';

export default combineReducers({
  work,
  works,
  selection,
  artists,
  artist,
  expandable,
  relatedWorks,
  artistWorks,
  giftCardBalance,
  product,
  productsList,
  artFilters,
  wallFilters,
  originalArtFilters,
  artistFilters,
  similarWorks,
  favorites,
  collections,
  designerPicks,
  giftGuides,
  walls,
  wall,
  originalArts,
  originalArt,
  authorOriginalArt,
  myWalls,
  collectionDetails,
  cartItems,
  checkoutProcess,
  mailSubscription,
  applePaySession,
  exploreArt,
  tapestries
});
