import Constants from 'arts/config/constants';
import { scrollPageTo } from 'arts/config/lib';
import createReducer from 'arts/config/create_reducer';
import { updateCollectionStoreItems } from 'arts/reducers/utils';
import deepEqual from 'deep-equal';

const PER_PAGE = 210;
const REQUEST_PAGE_SIZE = 70;
const REQUEST_COUNTS = parseInt(PER_PAGE / REQUEST_PAGE_SIZE, 10);

const getDefaultState = () => ({
  loading: false,
  error: null,
  collection: [],
  page: 1,
  totalPages: -1
});

const dispatchPagesLoaded = (page, responses, dispatch, constRoot) => {
  let collection = [];
  let totalPages = null;
  responses.forEach(r => {
    const [data, headers] = r;
    collection = [...collection, ...data];
    totalPages = totalPages || Math.ceil(parseInt(headers.get('X-total-Pages'), 10) / REQUEST_COUNTS);
  });
  dispatch({
    type: constRoot.FETCH_SUCCESS,
    data: collection,
    totalPages,
    page
  });
};

export const buildFetchWorksPage = (
  constRoot, loadPageApi, reducerRoot
) => (params) => (dispatch, getState) => {
  const { params: paramsAre } = getState()[reducerRoot];
  if (deepEqual(params, paramsAre)) {
    return;
  }

  const page = parseInt(params.page, 10) || 1;

  dispatch({ type: constRoot.FETCH, params });
  scrollPageTo(0, 250).then(() => {
    const rqs = [];
    for (let i = 0; i < REQUEST_COUNTS; i += 1) {
      const pageBase = (page - 1) * REQUEST_COUNTS;
      const paramsWithPage = {
        ...params,
        page: pageBase + i + 1,
        per: REQUEST_PAGE_SIZE
      };
      rqs.push(loadPageApi(paramsWithPage));
    }
    Promise.all(rqs).then(responses => {
      const { params: paramsWere } = getState()[reducerRoot];
      if (!deepEqual(params, paramsWere)) { return; }
      dispatchPagesLoaded(page, responses, dispatch, constRoot);
    }, errors => {
      dispatch({
        type: constRoot.FETCH_FAILURE,
        errors
      });
    });
  });
};

export const buildWorksReducer = (constRoot) => createReducer(getDefaultState(), {
  [constRoot.FETCH](state, { params }) {
    return {
      ...state,
      error: null,
      loading: true,
      params
    };
  },

  [constRoot.FETCH_SUCCESS](state, { data, page, totalPages }) {
    return {
      ...state,
      loading: false,
      page,
      totalPages,
      collection: data
    };
  },

  [constRoot.FETCH_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: action.errors ? action.errors.message : ''
    };
  },

  [constRoot.RESET]() {
    return getDefaultState();
  },

  [Constants.WorkStoreItems.ADDED](state, { workId, storeItemId }) {
    return {
      ...state,
      collection: updateCollectionStoreItems(state.collection, workId, storeItemId)
    };
  },

  [Constants.WorkStoreItems.REMOVED](state, { workId }) {
    return {
      ...state,
      collection: updateCollectionStoreItems(state.collection, workId, null)
    };
  }
});
