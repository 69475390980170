import React from 'react';
import T from 'prop-types';
import Expandable from 'arts/components/expandable';
import Constants, { CANVAS /* , TAPESTRY */ } from 'arts/config/constants';

const AdditionalInfo = ({
  hasSelection, aboutPaper, paperMaterial, withFrame
}) => {
  if (!hasSelection) { return null; }

  const lines = aboutPaper || [];
  if (paperMaterial === CANVAS && withFrame) {
    lines.push('A space 1/8" wide between the canvas and the moulding');
  }

  return (
    <Expandable title="Additional Print Info" eKey={ Constants.Expandable.KEYS.additionalInfo }>
      <div>
        {
          lines.map((l, i) => (
            <div key={ i }>
              -
              {' '}
              {l}
            </div>
          ))
        }
        <strong>
          As our prints and frames are custom made to order according to your
          specifications, all of our products are final sale and non-returnable.
        </strong>
      </div>
    </Expandable>
  );
};

AdditionalInfo.propTypes = {
  hasSelection: T.bool,
  withFrame: T.bool,
  paperMaterial: T.string,
  aboutPaper: T.arrayOf(T.string)
};

AdditionalInfo.defaultProps = {
  hasSelection: false,
  withFrame: false,
  paperMaterial: null,
  aboutPaper: []
};

export default AdditionalInfo;
