import React from 'react';
import Loader from 'arts/components/Loader';
import PropTypes from 'prop-types';
import {
  paperShape,
  frameShape,
  workShape
} from 'arts/reducers/work';
import Classes from './styles';
import PaperView from './paper_view';
import { calculatePaperProps, calculateFrameThickness } from './utils';

const renderFrame = (frame, { imageUrl, title, landscape }) => {
  const {
    frameWidth, frameHeight, frameMat, frameThickness, zoomPreviewUrl, frameLargeImageUrl, mount,
    isTapestry
  } = frame;
  const thickness = isTapestry ? 0 : frameThickness;
  const { width, borderH, borderW } = calculatePaperProps({
    paperHeight: frameHeight + ((frameThickness + frameMat) * 2),
    paperWidth: frameWidth + ((thickness + frameMat) * 2),
    printHeight: frameHeight,
    printWidth: frameWidth,
    landscape
  });
  const ftH = calculateFrameThickness(
    frameWidth + ((frameThickness + frameMat) * 2), 2 * frameThickness
  ) / 2;
  const ftV = calculateFrameThickness(
    frameHeight + ((frameThickness + frameMat) * 2), 2 * frameThickness
  ) / 2;
  const paperProps = {
    width,
    borderH,
    borderW,
    imageUrl,
    title,
    zoomPreviewUrl,
    backgroundImage: frameLargeImageUrl,
    frameThicknessH: ftH,
    frameThicknessV: ftV,
    mount,
    isTapestry
  };
  return (
    <PaperView unframed={ false } { ...paperProps } />
  );
};

const renderNoFrame = (paper, zoomPreviewUrl, { imageUrl, title, landscape }) => {
  const {
    paperHeight, paperWidth, printHeight, printWidth
  } = paper;
  const { width, borderH, borderW } = calculatePaperProps({
    paperHeight, paperWidth, printHeight, printWidth, landscape
  });
  const paperProps = {
    width, borderH, borderW, imageUrl, title, zoomPreviewUrl
  };
  return <PaperView unframed { ...paperProps } />;
};

const ImagePreview = ({
  selectedFrame,
  selectedPaper,
  data,
  otherImageUrl
}) => {
  const { zoomPreviewUrl: zoomPreviewUrlUsed } = selectedFrame || {};
  let content = null;

  if (otherImageUrl) {
    content = (
      <div className={ Classes.otherImage }>
        <img src={ otherImageUrl } alt="" />
      </div>
    );
  } else {
    const paper = selectedPaper || data.firstPaper;
    if (!paper) {
      content = <Loader />;
    } else if (!selectedFrame || !selectedFrame.frame) {
      content = renderNoFrame(paper, zoomPreviewUrlUsed, data);
    } else {
      content = renderFrame(selectedFrame, data);
    }
  }

  return (
    <div className={ Classes.previews }>
      { content }
    </div>
  );
};

ImagePreview.propTypes = {
  selectedFrame: frameShape,
  selectedPaper: paperShape,
  data: workShape,
  otherImageUrl: PropTypes.string
};

export default ImagePreview;
