/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { createGuestUserApi } from 'arts/config/api';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import AppClasses from '../Application/styles';
import Classes from './styles';
import { onUserNewletterSubscribed } from '../../config/analytics';

export const validateEmail = (value) => {
  let error = null;
  if (!value) {
    error = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

const GuestCheckout = () => {
  const ref = useRef(null);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const { email, subscribe } = values;
    createGuestUserApi({ email, subscribe }).then(({ errors, shaEmail, eventId }) => {
      if (shaEmail) {
        onUserNewletterSubscribed(shaEmail, eventId);
      }
      if (!ref.current) { return; }
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }
      window.location.reload();
    }).catch(() => {
      setSubmitting(false);
    });
    setSubmitting(true);
  };

  const initialValues = {
    email: '',
    subscribe: window.currentLocale.currentLocale === 'en'
  };

  return (
    <div ref={ ref }>
      <Formik
        initialValues={ initialValues }
        onSubmit={ onSubmit }
      >
        {
          ({
            isSubmitting
          }) => (
            <Form className={ classnames(Classes.genericForm, Classes.guest) }>
              <p className={ Classes.t1 }>Checkout As Guest</p>
              <p>You may create an account at the end of the checkout process if desired.</p>
              <div className={ Classes.guestRow }>
                <div className={ Classes.field }>
                  <label htmlFor="firstName">
                    Email
                  </label>
                  <Field
                    type="email"
                    name="email"
                    disabled={ isSubmitting }
                    validate={ validateEmail }
                  />
                  <ErrorMessage name="email" component="div" className={ Classes.error } />
                </div>
              </div>

              <div className={ Classes.guestRow }>
                <div className={ Classes.wideField }>
                  <label htmlFor="subscribe" className={ Classes.optionalLow }>
                    <Field
                      id="subscribe"
                      type="checkbox"
                      name="subscribe"
                      disabled={ isSubmitting }
                    />
                    { window.i18n_t.subscribe }
                  </label>
                </div>
              </div>

              <div className={ Classes.guestRow }>
                {
                  isSubmitting ? (
                    <Loader active inline />
                  ) : (
                    <button type="submit" className={ classnames(AppClasses.button, Classes.button) }>
                      CONTINUE
                    </button>
                  )
                }
              </div>

              <div className={ Classes.guestRow }>
                <div className={ classnames(Classes.wideField, Classes.lowI) }>
                  To learn more about how we use your information, read our&nbsp;
                  <a href={ `${ window.currentLocale.basename }/privacy` }>Privacy Policy</a>
                  &nbsp;and&nbsp;
                  <a href={ `${ window.currentLocale.basename }/terms_of_use` }>Terms Of Use</a>
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
};

export default GuestCheckout;
