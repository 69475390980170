import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import queryString from 'query-string';
import InfiniteScroll from 'arts/components/InfiniteScroll';
import Loader from 'arts/components/Loader';
import Container from './container';
import Artist from './artist';
import Filter from '../works/filter';
import AppClasses from '../Application/styles';
import GTMPageView from '../Application/data_layer';
import { trackEvent } from '../../config/analytics';

const VALID_QUERY_PARAMS = ['by_country'];

// eslint-disable-next-line react/prop-types
const ParentContainer = (parentRef) => ({ children }) => (
  <Container ref={ parentRef }>
    { children }
  </Container>
);

const clearFilters = (params) => {
  const out = {};
  VALID_QUERY_PARAMS.forEach(key => {
    const val = params[key];
    if (val) {
      out[key] = val;
    }
  });

  return out;
};

const Artists = ({
  fetchArtists,
  resetArtists,
  fetchArtistFilters,
  artistFilters,
  artists,
  loading,
  hasMore,
  page,
  history
}) => {
  const params = queryString.parse(window.location.search);
  const activeFilters = clearFilters(params);
  const canFetch = () => !loading && hasMore;

  const loadNextPage = () => {
    if (!canFetch()) { return; }
    fetchArtists(page + 1, activeFilters);
  };

  const parentRef = useRef(null);

  const renderArtist = (artist) => (
    <Artist
      { ...artist }
      key={ artist.id }
    />
  );

  useEffect(() => {
    if (!artistFilters && fetchArtistFilters) {
      fetchArtistFilters();
    }
  }, []);

  useEffect(() => {
    if (!loading && artists.length) {
      trackEvent('artists center', { 'artists count': artists.length });
    }
  }, [loading, artists.length]);

  const pc = useMemo(() => ParentContainer(parentRef), [parentRef]);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="category" section="artists" />
      <Filter
        path="/artists"
        label="Search artists by"
        history={ history }
        { ...activeFilters }
        filters={ VALID_QUERY_PARAMS }
        artFilters={ artistFilters }
        onChange={ resetArtists }
      />
      <InfiniteScroll
        items={ artists }
        itemRenderer={ renderArtist }
        onLoad={ loadNextPage }
        threshold={ 728 }
        itemsContainer={ pc }
        forwardRef={ parentRef }
      />
      {
        loading
          ? <Loader text="Loading more items, hold on for a sec..." />
          : null
      }
    </div>
  );
};

Artists.propTypes = {
  fetchArtists: PropTypes.func.isRequired,
  artists: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  resetArtists: PropTypes.func.isRequired,
  fetchArtistFilters: PropTypes.func.isRequired,
  artistFilters: PropTypes.arrayOf(PropTypes.any),
  history: ReactRouterPropTypes.history
};

export default Artists;
